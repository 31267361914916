import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import { TypeA } from "common/callouts";

import frontImage from "images/face/faceproducts/cleansing-gel-front.webp";
import backImage from "images/face/faceproducts/cleansing-gel-back.webp";
import frontZoomImage from "images/face/faceproducts/cleansing-gel-front.webp";
import backZoomImage from "images/face/faceproducts/cleansing-gel-back.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
import img8 from "images/callouts/sunproducts2x.webp";

//import img8 from "images/callouts/cout-footer-facecare.webp";
import "./cleansinggel.scss";

/* function BoldText({children}) {
  return <span style={{fontWeight: 'bold'}}>{children}</span>;
} */

function EucerinFaceHydratingCleansingGelPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-age-defense page-eucerin-face-hydrating-cleansing-gel page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Face Hydrating Cleansing Gel"
        pageDescription=""
      />

      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#106E9E"
              categoryName="FACE CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Face Hydrating Cleansing Gel"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
              // productImageAlt='Bottle of Eucerin Face Hydration Cleansing Gel'
            >
              <div className="functions">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Leaves face feeling soft, revitalized, and hydrated
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Face Hydrating Cleansing Gel
                  </span>{" "}
                  is a soap-free formulation that gently removes dirt, oil, and
                  makeup. It is enriched with hyaluronic acid to support skin’s
                  moisture balance.
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>For all skin types</span>
                  </li>
                  <li>
                    <span>Suitable for sensitive skin</span>
                  </li>
                  <li>
                    <span>
                      Unique formulation lifts and removes impurities from the
                      skin—for effective, gentle cleansing
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">Hyaluronic acid</p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>Hydrates skin and helps retain moisture</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold oceanicblue-text sub-title">
                    Formulated for all skin types
                  </p>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>NON-COMEDOGENIC </span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                    <li>
                      <span>ALCOHOL-FREE</span>
                    </li>
                  </ul>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>OIL-FREE</span>
                    </li>
                    <li>
                      <span>PARABEN-FREE</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin face family product line"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinFaceHydratingCleansingGelPage;
